<template>
	<ion-page>
		<!-- Title toolbar disamping icon hamburger menu -->
		<ion-header :translucent="true">
			<ion-toolbar color="ari">
				<ion-buttons slot="start">
					<ion-back-button
						@click="backPage('/ticket-complete')"
						default-href="/ticket-complete"
						text=""
					></ion-back-button>
				</ion-buttons>
				<ion-title>Detail Tiket</ion-title>
				<ion-progress-bar
					v-if="store.state.isLoading"
					type="indeterminate"
				></ion-progress-bar>
			</ion-toolbar>
		</ion-header>

		<ion-content :fullscreen="true" class="ion-padding">
			<!-- <pre>{{ ticket.detail.TICKET_CHAT_HISTORY }}</pre> -->
			<div v-if="ticket.detail != null">
				<!-- Tiket -->
				<div class="ion-margin wrapper">
					<div
						class="bg-title ion-padding-horizontal"
						@click="items.tiket = !items.tiket"
					>
						<span>TIKET</span>
						<svg
							v-if="items.tiket"
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							fill="currentColor"
							class="bi bi-chevron-down icon-spe"
							viewBox="0 0 16 16"
						>
							<path
								fill-rule="evenodd"
								d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
							/>
						</svg>
						<svg
							v-else
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							fill="currentColor"
							class="bi bi-chevron-right icon-spe"
							viewBox="0 0 16 16"
						>
							<path
								fill-rule="evenodd"
								d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
							/>
						</svg>
					</div>

					<div class="content ion-padding" v-if="items.tiket">
						<ion-grid>
							<ion-row>
								<ion-col size="5" class="bold">
									Tiket No
								</ion-col>
								<ion-col>
									{{ ticket.detail.TICKET_NO }}
								</ion-col>
							</ion-row>
							<ion-row v-if="ticket.detail.START_DATE != null">
								<ion-col size="5" class="bold">
									Tgl Register
								</ion-col>
								<ion-col>
									{{ ticket.detail.START_DATE }}
								</ion-col>
							</ion-row>
							<ion-row v-if="ticket.detail.END_DATE != null">
								<ion-col size="5" class="bold">
									Tgl Selesai
								</ion-col>
								<ion-col>
									{{ ticket.detail.END_DATE }}
								</ion-col>
							</ion-row>
						</ion-grid>
					</div>
				</div>

				<!-- Lines -->
				<div class="lines ion-margin"></div>

				<!-- Pasien -->
				<div class="ion-margin wrapper">
					<div
						class="bg-title ion-padding-horizontal"
						@click="items.pasien = !items.pasien"
					>
						<span>PASIEN</span>
						<svg
							v-if="items.pasien"
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							fill="currentColor"
							class="bi bi-chevron-down icon-spe"
							viewBox="0 0 16 16"
						>
							<path
								fill-rule="evenodd"
								d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
							/>
						</svg>
						<svg
							v-else
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							fill="currentColor"
							class="bi bi-chevron-right icon-spe"
							viewBox="0 0 16 16"
						>
							<path
								fill-rule="evenodd"
								d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
							/>
						</svg>
					</div>
					<div class="content ion-padding" v-if="items.pasien">
						<ion-grid>
							<ion-row>
								<ion-col size="5" class="bold">
									Nama Pasien
								</ion-col>
								<ion-col>
									{{ ticket.detail.MEMBER_NAME }}
								</ion-col>
							</ion-row>
							<ion-row>
								<ion-col size="5" class="bold">
									Perusahaan
								</ion-col>
								<ion-col>
									{{ ticket.detail.POLICY_NAME }}
								</ion-col>
							</ion-row>
							<ion-row>
								<ion-col size="5" class="bold">
									Tanggal lahir
								</ion-col>
								<ion-col>
									{{ formatDob(ticket.detail.MEMBER_DOB) }}
								</ion-col>
							</ion-row>
							<ion-row>
								<ion-col size="5" class="bold">
									Gender
								</ion-col>
								<ion-col>
									{{ ticket.detail.GENDER_NAME_INA }}
								</ion-col>
							</ion-row>
							<ion-row>
								<ion-col size="5" class="bold">
									Tinggi Badan
								</ion-col>
								<ion-col>
									{{ parseInt(ticket.detail.MEMBER_TALL) }} cm
								</ion-col>
							</ion-row>
							<ion-row>
								<ion-col size="5" class="bold">
									Berat Badan
								</ion-col>
								<ion-col>
									{{ parseInt(ticket.detail.MEMBER_WEIGHT) }} kg
								</ion-col>
							</ion-row>
							<ion-row v-if="ticket.detail.TICKET_INDICATION != null">
								<ion-col size="5" class="bold">
									Indikasi
								</ion-col>
								<ion-col>
									{{ ticket.detail.TICKET_INDICATION }}
								</ion-col>
							</ion-row>
							<ion-row v-if="ticket.detail.MEMBER_ADDRESS != null">
								<ion-col size="5" class="bold">
									Alamat Pengiriman Obat
								</ion-col>
								<ion-col>
									{{ ticket.detail.MEMBER_ADDRESS }}
								</ion-col>
							</ion-row>
							<ion-row v-if="ticket.detail.TICKET_GESTATIONAL_AGE != '0'">
								<ion-col size="5" class="bold">
									Umur Kandungan
								</ion-col>
								<ion-col>
									{{ ticket.detail.TICKET_GESTATIONAL_AGE }} minggu
								</ion-col>
							</ion-row>
							<!-- <ion-row>
						<ion-col size="5" class="bold">
							No HP
						</ion-col>
						<ion-col>
							0823-1234-1234
						</ion-col>
					</ion-row> -->
						</ion-grid>
					</div>
				</div>

				<!-- Lines -->
				<div class="lines ion-margin"></div>

				<!-- Dokter -->
				<div class="ion-margin wrapper">
					<div
						class="bg-title ion-padding-horizontal"
						@click="items.dokter = !items.dokter"
					>
						<span>DOKTER</span>
						<svg
							v-if="items.dokter"
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							fill="currentColor"
							class="bi bi-chevron-down icon-spe"
							viewBox="0 0 16 16"
						>
							<path
								fill-rule="evenodd"
								d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
							/>
						</svg>
						<svg
							v-else
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							fill="currentColor"
							class="bi bi-chevron-right icon-spe"
							viewBox="0 0 16 16"
						>
							<path
								fill-rule="evenodd"
								d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
							/>
						</svg>
					</div>
					<div class="content ion-padding dokter" v-if="items.dokter">
						<ion-grid>
							<ion-row>
								<ion-col size="5" class="bold">
									Nama Dokter
								</ion-col>
								<ion-col>
									{{ ticket.detail.DOCTOR_NAME }}
								</ion-col>
							</ion-row>
							<ion-row>
								<ion-col size="5" class="bold">
									Diagnosa
								</ion-col>
								<ion-col
									v-if="ticket.detail.DiagnoseDataReponseModel.length > 0"
								>
									{{
										ticket.detail.DiagnoseDataReponseModel[0]
											.TICKET_DOCTOR_DIAGNOSE
									}}
								</ion-col>
								<ion-col v-else>-</ion-col>
							</ion-row>
							<!-- <ion-row>
								<ion-col size="5" class="bold">
									Catatan Dokter
								</ion-col>
								<ion-col>
									{{
										ticket.detail.doctor_note === ""
											? "-"
											: ticket.detail.doctor_note
									}}
								</ion-col>
							</ion-row> -->
						</ion-grid>
					</div>
				</div>

				<!-- Lines -->
				<div class="lines ion-margin"></div>

				<!-- Pasien -->
				<div class="ion-margin wrapper">
					<div
						class="bg-title ion-padding-horizontal"
						@click="items.obat = !items.obat"
					>
						<span>DETAIL OBAT</span>
						<svg
							v-if="items.obat"
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							fill="currentColor"
							class="bi bi-chevron-down icon-spe"
							viewBox="0 0 16 16"
						>
							<path
								fill-rule="evenodd"
								d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
							/>
						</svg>
						<svg
							v-else
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							fill="currentColor"
							class="bi bi-chevron-right icon-spe"
							viewBox="0 0 16 16"
						>
							<path
								fill-rule="evenodd"
								d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
							/>
						</svg>
					</div>
					<div class="content" v-if="items.obat">
						<div class="obat-note-wrap">
							<span class="obat-note"
								>Hubungi penyedia layanan kesehatan terdekat jika kondisi Anda
								tidak membaik</span
							>
						</div>
						<!-- <ion-grid
							v-for="(data, idx) in ticket.detail.MedicineDataReponseModel"
							:key="idx"
							class="medicine"
						>
							<div class="obat-list">
								<h3>
									{{ idx + 1 }}.) {{ data.TICKET_MEDICINE_NAME }} ({{
										parseInt(data.TICKET_MEDICINE_QTY)
									}}
									{{ data.TICKET_MEDICINE_UNIT }})
								</h3>
								<h4>{{ data.MEDICINE_DOSE_INFO }}</h4>
								<span>{{ data.MEDICINE_DOSE_TIME_INFO }}</span>
								<span v-if="data.TICKET_MEDICINE_NOTES != ''"
									>Note : {{ data.TICKET_MEDICINE_NOTES }}</span
								>
							</div>
						</ion-grid> -->
						<ion-list v-if="ticket.detail != null">
							<ion-item
								v-for="(d, i) in ticket.detail.MedicineDataReponseModel"
								:key="i"
								color="light"
							>
								<ion-icon
									:icon="documentTextOutline"
									class="document"
								></ion-icon>
								<ion-label class="ion-text-wrap">
									<span>
										<b>{{ i + 1 }}). {{ d.TICKET_MEDICINE_NAME }}</b> ({{
											parseInt(d.TICKET_MEDICINE_QTY)
										}}
										{{ d.TICKET_MEDICINE_UNIT }})<br
									/></span>
									<span style="text-transform:lowercase;"
										>{{ d.MEDICINE_DOSE_INFO }}<br
									/></span>
									<span>{{ d.MEDICINE_DOSE_TIME_INFO }}<br /></span>
									<span v-if="d.TICKET_MEDICINE_NOTES"
										>Catatan: {{ d.TICKET_MEDICINE_NOTES }}<br
									/></span>
									<span v-if="d.TICKET_MEDICINE_PRICE">
										Harga: Rp{{
											convertToRupiah(parseInt(d.TICKET_MEDICINE_PRICE))
										}}</span
									>
								</ion-label>
							</ion-item>
						</ion-list>
					</div>
				</div>

				<!-- Lines -->
				<div class="lines ion-margin"></div>

				<!-- Riwayat Percakapan -->
				<div class="ion-margin wrapper">
					<div
						class="bg-title ion-padding-horizontal"
						@click="items.chat = !items.chat"
					>
						<span>RIWAYAT PERCAKAPAN</span>
						<svg
							v-if="items.chat"
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							fill="currentColor"
							class="bi bi-chevron-down icon-spe"
							viewBox="0 0 16 16"
						>
							<path
								fill-rule="evenodd"
								d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
							/>
						</svg>
						<svg
							v-else
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							fill="currentColor"
							class="bi bi-chevron-right icon-spe"
							viewBox="0 0 16 16"
						>
							<path
								fill-rule="evenodd"
								d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
							/>
						</svg>
					</div>
					<div class="content ion-padding" v-if="items.chat">
						<div style="text-align:center; padding-top:10px;">
							<ion-button
								size="small"
								color="primary"
								class="center-button"
								:routerLink="ticket.chatHistoryUrl"
								routerDirection="forward"
								>Lihat Riwayat Percakapan</ion-button
							>
						</div>
					</div>
				</div>

				<!-- Lines -->
				<div class="lines ion-margin"></div>
				<!-- <pre>{{ticket}}</pre> -->
			</div>
			<!-- <pre>{{ store.state.ticket.ticketInfo }}</pre> -->
		</ion-content>
		<bottom-tab-bar></bottom-tab-bar>
	</ion-page>
</template>

<script>
import {
	IonList,
	IonIcon,
	IonLabel,
	IonItem,
	IonButtons,
	IonButton,
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonGrid,
	IonRow,
	IonCol,
	IonBackButton,
	IonProgressBar,
	onIonViewDidEnter,
	onIonViewWillLeave,
} from "@ionic/vue";
import { documentTextOutline } from "ionicons/icons";
import { inject, reactive, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import BottomTabBar from "@/components/BottomTabBar.vue";
import { formatDob } from "../../store/methods/utils/utils";
//import DoctorEndChat from "@/components/DoctorEndChat.vue";
export default {
	name: "TicketInformationDetail",
	components: {
		IonList,
		IonIcon,
		IonLabel,
		IonItem,
		IonButtons,
		IonButton,
		IonContent,
		IonHeader,
		IonPage,
		IonTitle,
		IonToolbar,
		IonGrid,
		IonRow,
		IonCol,
		IonBackButton,
		IonProgressBar,
		BottomTabBar,
		// DoctorEndChat,
	},
	setup() {
		const store = inject("store");
		const route = useRoute();
		const router = useRouter();
		const ticketId = computed(() => route.params.id);
		const ticket = reactive({
			detail: null,
			chatHistoryUrl: null,
		});

		function backPage(page) {
			router.replace(page);
		}

		//get detail ticket
		async function getDetailTicket() {
			store.state.isLoading = true;
			try {
				const result = await store.ticket.ticketInformation(ticketId.value);
				if (result.data.ResponseCode === "000") {
					ticket.detail = result.data;
					store.state.isLoading = false;
					localStorage.setItem(
						"doctorChatHistory",
						result.data.TICKET_CHAT_HISTORY
					);
					localStorage.setItem(
						"doctorChatHistoryPatientName",
						result.data.MEMBER_NAME
					);
					//console.log("ticket detail", result.data.MEMBER_NAME);
				}
			} catch (error) {
				store.state.isLoading = false;
			}
		}
		function convertToRupiah(angka) {
			var rupiah = "";
			var angkarev = angka
				.toString()
				.split("")
				.reverse()
				.join("");

			for (var i = 0; i < angkarev.length; i++)
				if (i % 3 == 0) rupiah += angkarev.substr(i, 3) + ".";

			return rupiah
				.split("", rupiah.length - 1)
				.reverse()
				.join("");
		}

		onIonViewDidEnter(() => {
			getDetailTicket();
			ticket.chatHistoryUrl = "/chat-history/" + ticketId.value;
		});
		onIonViewWillLeave(() => {
			ticket.detail = null;
			localStorage.removeItem("doctorChatHistoryPatientName");
			localStorage.removeItem("doctorChatHistory");
		});

		return {
			store,
			ticket,
			documentTextOutline,
			formatDob,
			convertToRupiah,
			backPage,
		};
	},
	data() {
		return {
			items: {
				tiket: true,
				pasien: false,
				dokter: false,
				obat: false,
				chat: false,
				status: false,
			},
		};
	},
	// methods: {
	// 	IsOpenTrue() {
	// 		this.isOpen = true;
	// 	},
	// 	IsOpenFalse(v) {
	// 		this.isOpen = v;
	// 	},
	// },
};
</script>

<style scoped>
ion-item {
	cursor: pointer;
}
.bg-information {
	background-color: red;
}
.personIcon {
	color: #1a2674;
	margin-right: 10px;
	font-size: 40px;
}
.medicine {
	margin-bottom: 10px;
}
.medicine > span {
	margin-bottom: 10px;
	display: block;
}
.medicine > ion-row {
	padding: 0 1rem;
}
.medicine > ion-row:nth-child(even) {
	background: #f3f9fe;
}
.bg-title {
	background-color: #f3f9fe !important;
	padding-top: 0.8rem;
	padding-bottom: 0.8rem;
	border-bottom: 1px solid #e0e9f0;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
}
.lines {
	background-color: rgba(204, 204, 204, 0);
	height: 1px;
}
.wrapper {
	border: 1px solid #e0e9f0;
}

.content {
	/* margin: auto 20px 20px 20px; */
	background-color: white;
	/* padding: 12px; */
}
.content ul {
	list-style: none;
	padding: 0;
	margin: 0;
	line-height: 30px;
}
.content-box {
	top: 10%;
}
.content-box .content-title h3 {
	font-size: 28px;
	margin-bottom: 15px;
}

.content-box .timeline-content ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.content-box .timeline-content ul li {
	margin-bottom: -19px;
}
.content-box .timeline-content ul li a {
	text-decoration: none;
}
.content-box .timeline-content ul li span {
	font-size: 14px;
	color: rgb(122, 122, 122);
	display: block;
	margin-bottom: -15px;
	padding-left: 20px;
	position: relative;
}
.content-box .timeline-content ul li span::before {
	content: "";
	position: absolute;
	height: 8px;
	width: 8px;
	border: 3px solid rgb(100, 100, 100);
	top: 8px;
	left: 0;
	border-radius: 50px;
}
.content-box .timeline-content ul li p {
	font-size: 16px;
	line-height: 30px;
	border: 3px solid rgb(100, 100, 100);
	border-width: 0 0 0 3px;
	padding: 5px 0 5px 16px;
	margin-left: 5px;
	color: darkslategray;
}
.document {
	color: #363636;
	margin-right: 5px;
	margin-left: 0px;
	font-size: 30px;
}
ion-title {
	font-size: 16px;
}
ion-col,
ion-row,
ion-grid {
	padding-left: 0;
	margin-left: 0;
	text-align: left;
	line-height: 25px;
}
ion-col {
	font-size: 12px;
}
ion-col.bold {
	font-weight: bold;
	font-size: 14px;
}

.diagnosaList {
	padding: 0;
	padding-left: 15px;
}
.obat-note-wrap {
	margin-top: 20px;
	text-align: center;
	margin-bottom: 10px;
}
.obat-note {
	padding: 10px;
	margin-top: 25px;
	color: #aaaaaa;
	font-size: 0.8rem;
}
.obat-list > h3 {
	font-size: 0.85rem;
	font-weight: normal;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin: 2px 0;
}
.obat-list > h4 {
	font-size: 0.85rem;
	font-weight: normal;
	margin: 2px 0;
}
.obat-list > span {
	font-size: 0.85rem;
	font-weight: normal;
	margin: 0;
	color: #888888;
	display: block;
	margin: 0;
	padding: 0;
}
</style>
