<template>
	<ion-toolbar class="hidden-lg-up">
		<ion-tabs>
			<ion-router-outlet></ion-router-outlet>
			<ion-tab-bar slot="bottom">
				<ion-tab-button tab="home" href="/conversation">
					<ion-icon :icon="createOutline"></ion-icon>
					<ion-label>Permintaan</ion-label>
					<!-- <img src="@/assets/relidoc/permintaan.svg" alt="" class="icon-bar">
					<span class="text-bar">Permintaan</span> -->
				</ion-tab-button>

				<ion-tab-button tab="/ticket-onprocess" href="/ticket-onprocess">
					<ion-icon :icon="chatbubblesOutline"></ion-icon>
					<ion-label>Konsultasi</ion-label>
					<!-- <img src="@/assets/relidoc/konsultasi.svg" alt="" class="icon-bar">
					<span class="text-bar">Konsultasi</span> -->
				</ion-tab-button>
				<ion-tab-button tab="ticket-complete" href="/ticket-complete">
					<ion-icon :icon="checkmarkCircleOutline"></ion-icon>
					<ion-label>Selesai</ion-label>
					<!-- <img src="@/assets/relidoc/selesai.svg" alt="" class="icon-bar">
					<span class="text-bar">Selesai</span> -->
				</ion-tab-button>
				<ion-tab-button tab="profile" href="/profile">
					<ion-icon :icon="personOutline"></ion-icon>
					<ion-label>Profile Akun</ion-label>
					<!-- <img src="@/assets/relidoc/profile.svg" alt="" class="icon-bar">
					<span class="text-bar">Profile Akun</span> -->
				</ion-tab-button>
			</ion-tab-bar>
		</ion-tabs>
	</ion-toolbar>
</template>

<script>
import { inject } from "vue";
//import { useRoute } from "vue-router";
import {
	chatboxOutline,
	syncOutline,
	checkmarkDoneCircleOutline,
	checkmarkCircleOutline,
	personOutline,
	createOutline,
	peopleOutline,
	chatbubblesOutline,
} from "ionicons/icons";
import {
	IonToolbar,
	IonTabs,
	IonTabBar,
	IonTabButton,
	IonIcon,
	IonLabel,
	IonRouterOutlet,
} from "@ionic/vue";
export default {
	name: "BottomTabBar",
	components: {
		IonToolbar,
		IonTabs,
		IonTabBar,
		IonTabButton,
		IonIcon,
		IonLabel,
		IonRouterOutlet,
	},
	setup() {
		// init reactivity with vue inject
		const store = inject("store");
		//useRoute to detect active page to prevent double click function
		//const route = useRoute();

		function getConversation() {
			store.ticket.listRequestConsultation();
		}
		function getTicketOnProcess() {
			store.ticket.listStartConsultation();
		}
		function getTicketComplete() {
			store.ticket.listTicketComplete();
		}
		return {
			chatboxOutline,
			syncOutline,
			checkmarkDoneCircleOutline,
			personOutline,
			createOutline,
			peopleOutline,
			checkmarkCircleOutline,
			chatbubblesOutline,
			getConversation,
			getTicketOnProcess,
			getTicketComplete,
			store,
		};
	},
};
</script>

<style scoped>
@media (min-width: 992px) {
	.hidden-lg-up {
		display: none !important;
	}
}
ion-tab-button {
	--color-selected: var(--ion-color-aridelapan);
}
.icon-bar {
	width: 25px;
}
.text-bar {
	font-size: 11px;
}
ion-toolbar {
	height: 55px;
}
ion-tab-button {
	padding-bottom: 5px !important;
}
</style>
